import Head from 'next/head';

type Props = {
  keywords?: {key: string; content: string[]};
  description?: {key: string; content: string};
  favicon?: string;
  indexable?: boolean;
  title: string;
};
export const HtmlHead = ({
  keywords,
  description,
  favicon,
  title,
  indexable = true,
}: Props) => {
  return (
    <Head>
      {favicon && <link rel="shortcut icon" href={favicon} />}
      {description && (
        <meta
          name="description"
          content={description.content}
          key={description.key}
        />
      )}
      {keywords && (
        <meta
          name="keywords"
          content={keywords.content.join(',')}
          key={keywords.key}
        />
      )}
      <meta name="robots" content={indexable ? 'index' : 'noindex'} />
      <title>{title}</title>
    </Head>
  );
};
