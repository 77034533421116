import getConfig from 'next/config';
import {QueryKey, useQuery, UseQueryOptions} from 'react-query';
import {useSelector} from 'react-redux';
import {FaqProps} from '../components/Faq/index';
import {languageSelector} from '../lib/context/selectors';
import {fapper} from '../utils/fapper';

export interface FaqResponse {
  contentItems: FaqProps[];
}

const {publicRuntimeConfig} = getConfig();

const getFaqContent = async (
  tags: string[],
  language: string,
): Promise<FaqProps> => {
  const tagParam = tags.join(';');
  const result: FaqResponse = await fapper.get(
    `${publicRuntimeConfig.NEXT_PUBLIC_UMBRACO_API}?culture=${language}&tags=${tagParam}&type=faqset`,
  );
  return result.contentItems[0];
};

const useFaqContent = (
  tags: string[],
  options: UseQueryOptions<FaqProps, unknown, FaqProps, QueryKey> = {
    refetchOnWindowFocus: false,
  },
) => {
  const language = useSelector(languageSelector);
  return useQuery<FaqProps>(
    ['faqs'],
    () => getFaqContent(tags, language),
    options,
  );
};

export {useFaqContent, getFaqContent};
