import getConfig from 'next/config';
import {QueryKey, useQuery, UseQueryOptions} from 'react-query';
import {useSelector} from 'react-redux';
import {languageSelector} from '../lib/context/selectors';
import {fapper} from '../utils/fapper';
import {ContentResponse, VisualList} from './types';

const {publicRuntimeConfig} = getConfig();

const getVisualListContent = async (
  tagParams: string[],
  language: string,
): Promise<VisualList> => {
  const result: ContentResponse<VisualList> = await fapper.get(
    `${
      publicRuntimeConfig.NEXT_PUBLIC_UMBRACO_API
    }?culture=${language}&tags=${tagParams.join(';')}&type=list`,
  );
  return result.contentItems[0];
};

const useVisualListContent = (
  tagParams: string[],
  options: UseQueryOptions<VisualList, unknown, VisualList, QueryKey> = {
    refetchOnWindowFocus: false,
  },
) => {
  const language = useSelector(languageSelector);
  return useQuery<VisualList>(
    ['visuallist'],
    () => getVisualListContent(tagParams, language!!),
    options,
  );
};

export {useVisualListContent, getVisualListContent};
