import React from 'react';
import {Slug} from '../components/Slug';
import {useTemplate} from '../api/getTemplate';
import getConfig from 'next/config';
import {InitialStateLoader} from '../lib/context/InitialStateLoader';

const Index = () => {
  const {publicRuntimeConfig} = getConfig();
  const {data} = useTemplate(
    `${publicRuntimeConfig.NEXT_PUBLIC_CDN_URL}${publicRuntimeConfig.NEXT_PUBLIC_ENVIRONMENT_TEMPLATE_FILENAME}`,
  );

  if (!data) {
    return null;
  }

  return (
    <InitialStateLoader>
      <Slug data={data} />
    </InitialStateLoader>
  );
};

export default Index;

// This function looks pointless, but it's needed for process.env to be populated since we use runtimeConfig in next.config.js
Index.getInitialProps = async () => {
  return {};
};
