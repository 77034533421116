import {QueryKey, useQuery, UseQueryOptions} from 'react-query';
import {fapper} from '../utils/fapper';
import {TemplateResponse} from './types';

const getTemplate = async (template: string): Promise<TemplateResponse> => {
  const result: TemplateResponse = await fapper.get(template);
  return result;
};

const useTemplate = (
  template: string,
  options: UseQueryOptions<
    TemplateResponse,
    unknown,
    TemplateResponse,
    QueryKey
  > = {
    refetchOnWindowFocus: false,
  },
) => {
  return useQuery<TemplateResponse>(
    ['template'],
    () => getTemplate(template),
    options,
  );
};

export {useTemplate, getTemplate};
