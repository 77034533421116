import {Box, Image, Link as ChakraLink, Flex} from '@chakra-ui/react';
import {StepProp} from '../ServicesContentSteps/DefaultContentSteps';
import {ArrowForwardIcon} from '@chakra-ui/icons';
import {MotionBox} from '../MotionBox/MotionBox';
import Link from 'next/link';

type Content = {type: string; text: string};

export type Step = {step: string; title: string; content: Content};
export type Service = {
  id: number;
  title: string;
  slug: string;
  imageUrl: string;
  imageAlt?: string;
  description?: string;
  steps: StepProp[];
  showOSLink?: boolean;
};

export interface Props {
  service: Service;
  onServiceOpen: () => void;
  lazyLoading: boolean;
}

export const ServiceView = ({service, onServiceOpen, lazyLoading}: Props) => {
  return (
    <MotionBox whileHover={{scale: 1.01}} transition={{ease: 'easeInOut'}}>
      <Box
        layerStyle="tile"
        role="group"
        onClick={onServiceOpen}
        data-cy={'ServiceView' + service.title}>
        <Link href={`/services/${service.slug}`} passHref>
          <ChakraLink
            sx={{
              _hover: {
                textDecoration: 'none',
              },
            }}>
            <Image
              loading={lazyLoading ? 'lazy' : 'eager'}
              height={128}
              width="100%"
              borderTopRadius="xl"
              src={service.imageUrl}
              alt={service.imageAlt ?? ''}
              objectFit="cover"
              backgroundColor="grey"
            />
            <Box sx={{p: 6}}>
              <Flex alignItems="center" fontWeight="normal">
                {service.title}
                <ArrowForwardIcon
                  color="faqIconColor"
                  w={4}
                  h={4}
                  ml={1}
                  sx={{
                    transition: 'all 0.3s ease',
                    _groupHover: {
                      transform: 'translateX(5px)',
                    },
                  }}
                />
              </Flex>
            </Box>
          </ChakraLink>
        </Link>
      </Box>
    </MotionBox>
  );
};
