import React from 'react';
import {Box, HTMLChakraProps} from '@chakra-ui/react';

interface Props extends HTMLChakraProps<'div'> {
  children: React.ReactNode | React.ReactNode[];
}
export const ContactWrapper = ({children, ...rest}: Props) => {
  return (
    <Box
      bg="contactBackground"
      as="section"
      color="contactColor"
      pt={[10, 14]}
      {...rest}>
      {children}
    </Box>
  );
};
