import {BlogContent} from '../../api/getBlogOverviewContent';
import {Context, Intent, Tag} from '../../lib/context/types';

interface BlogWithContext extends BlogContent {
  tag?: Tag;
}

/**
 * Return true if intent A is less than or equal intent B
 */
export const lte = (intentA: Intent, intentB: Intent) => {
  const intentOrder: Intent[] = ['awareness', 'interest', 'decision', 'action'];
  const indexA = intentOrder.findIndex((item) => item === intentA);
  const indexB = intentOrder.findIndex((item) => item === intentB);
  return indexA >= indexB;
};

export const sortBlogs = (
  blogs: BlogContent[],
  tags: Context,
  cleanup = true,
) => {
  const tagsWithIntent = tags.filter((item) => item.intent !== undefined);

  const enrichedBlogs: BlogWithContext[] = blogs.map((blog) => {
    const tag = tagsWithIntent.find(
      (item) =>
        blog.relevantServices &&
        blog.relevantServices.find((b) => b.id === item.value),
    );
    return {
      ...blog,
      tag,
    };
  });

  // If compareFunction(a, b) returns a value > than 0, sort b before a.
  // If compareFunction(a, b) returns a value ≤ 0, leave a and b in the same order.
  const compareFunction = (blogA: BlogWithContext, blogB: BlogWithContext) => {
    if (!blogA.tag && blogB.tag) {
      // als A geen context heeft, maar B wel dan B prio
      return 1;
    }

    if (!blogB.tag && blogA.tag) {
      // als B geen context heeft, maar A wel dan A prio
      return -1;
    }

    // als beide geen context, sort hen dan by publishDate
    if (!blogA.tag && !blogB.tag) {
      if (blogA.publishDate < blogB.publishDate) {
        return -1;
      } else {
        return 1;
      }
    }

    if (blogA.tag!.intent === blogB.tag!.intent) {
      if (blogA.tag!.score === blogB.tag!.score) {
        // order by publishDate
        if (blogA.publishDate < blogB.publishDate) {
          return -1;
        } else {
          return 1;
        }
      } else {
        // order by score
        if (blogA.tag!.score < blogB.tag!.score) {
          return 1;
        } else {
          return -1;
        }
      }
    }

    // order by intent
    if (lte(blogA.tag!.intent!, blogB.tag!.intent!)) {
      return -1;
    } else {
      return 1;
    }
  };

  enrichedBlogs.sort(compareFunction);

  // remove added tag prop
  const result = enrichedBlogs.map((item) => {
    if (cleanup) delete item.tag;
    return item;
  });

  return result;
};
