import {useDispatch} from 'react-redux';
import {Dispatch} from 'redux';
import {addOrUpdateIntent} from '../../lib/context/actionCreators';
import {Action} from '../../lib/context/types';
import {linkState} from '../../utils/linkState';
import {ServiceView, Service} from './ServiceView';

interface Props {
  service: Service;
  lazyLoading: boolean;
}

export const ServiceController = ({service, lazyLoading}: Props) => {
  const dispatch: Dispatch<Action> = useDispatch();

  const onServiceOpen = () => {
    dispatch(addOrUpdateIntent(linkState(service.id, 100, 'decision')));
  };

  return (
    <ServiceView
      lazyLoading={lazyLoading}
      onServiceOpen={onServiceOpen}
      service={service}
    />
  );
};
