import {Box, Text, Image, Flex, Heading} from '@chakra-ui/react';
import React from 'react';
import {ComponentWrapper} from '../ComponentWrapper';
import {HtmlContent} from '../_shared/HtmlContent';

export interface IntroProps {
  title: string;
  description: string;
  imageUrl: string;
  imageAlt?: string;
  lazyLoading: boolean;
}

export const IntroView = ({
  title,
  description,
  imageUrl,
  imageAlt = '',
  lazyLoading,
}: IntroProps) => {
  const customLinkStyles = {
    textColor: 'introLinkColor',
  };
  return (
    <Box as="section" position="relative" minH={[null, 466]}>
      <Image
        loading={lazyLoading ? 'lazy' : 'eager'}
        alt={imageAlt}
        src={imageUrl}
        data-cy="intro-image"
        sx={{
          position: [null, 'absolute'],
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      />
      <ComponentWrapper color="introColor" sx={{p: [0, 4]}}>
        <Flex
          m={{base: -4, xl: 0}}
          data-cy="intro"
          overflow="hidden"
          flexDirection={['column-reverse', 'row']}>
          <Flex
            flexDirection="column"
            bg="introBackground"
            mt={[0, 24]}
            mb={[0, 8]}
            borderRadius={[0, '12px']}
            px={8}
            py={[5, 5]}
            position="relative"
            w={[null, 545]}>
            <Heading as="h1" variant="h1" mb={10}>
              {title}
            </Heading>
            <HtmlContent
              lazyLoading={lazyLoading}
              data={description}
              customLinkStyles={customLinkStyles}
            />
            <Text mb="0" mt="auto">
              <strong>
                <em>Fit</em>
              </strong>{' '}
              to explore your world
            </Text>
            <Image
              src="/assets/images/khs-cross.svg"
              alt=""
              sx={{
                height: '80px',
                width: '80px',
                position: 'absolute',
                bottom: ['-2px', 0],
                right: ['-1px', '-40px'],
              }}
            />
          </Flex>
        </Flex>
      </ComponentWrapper>
    </Box>
  );
};
