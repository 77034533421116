import React from 'react';
import {SimpleGrid, Heading} from '@chakra-ui/react';
import {BlogItem} from './BlogItem';
import {BlogContent} from '../../api/getBlogOverviewContent';
import {BlogOverviewWrapper} from './BlogOverviewWrapper';
import {useTranslation} from 'react-i18next';

export interface BlogOverviewProps {
  title: string;
  blogs: BlogContent[];
  prominentBlogs: BlogContent[];
  lazyLoading: boolean;
}

export const BlogOverviewView = ({
  title,
  blogs,
  prominentBlogs,
  lazyLoading,
}: BlogOverviewProps) => {
  const {t} = useTranslation();

  const RenderBlogItem = (item: BlogContent) => (
    <BlogItem
      lazyLoading={lazyLoading}
      slug={item.slug}
      title={item.title}
      imageUrl={item.imageUrl}
      imageAlt={item.imageAlt}
      publishDate={item.publishDate}
      relevantServices={item.relevantServices}
    />
  );

  return (
    <BlogOverviewWrapper>
      <Heading
        as="h2"
        variant="h2"
        mb={10}
        fontWeight="normal"
        color="blogOverviewHeadingColor">
        {title}
      </Heading>
      {prominentBlogs.length > 0 && (
        <>
          <Heading as="h4" variant="h4">
            {t('highlighted_for_you')}
          </Heading>
          <SimpleGrid
            columns={[1, 2]}
            spacing={[0, 7]}
            marginBottom={16}
            data-cy="ProminentBlogView">
            {prominentBlogs.map((item, i) => (
              <RenderBlogItem {...item} key={i} />
            ))}
          </SimpleGrid>
          <Heading as="h4" variant="h4">
            {t('everything')}
          </Heading>
        </>
      )}
      <SimpleGrid columns={[1, 2]} spacing={[0, 7]}>
        {blogs
          .sort(
            (a, b) =>
              new Date(b.publishDate).getTime() -
              new Date(a.publishDate).getTime(),
          )
          .map((item, i) => (
            <RenderBlogItem {...item} key={i} />
          ))}
      </SimpleGrid>
    </BlogOverviewWrapper>
  );
};
