import {Box, Image, Text, Link as ChakraLink} from '@chakra-ui/react';
import Link from 'next/link';
import {useTranslation} from 'react-i18next';
import {useSelector, useDispatch} from 'react-redux';
import {languageSelector} from '../../lib/context/selectors';
import {MotionBox} from '../MotionBox/MotionBox';
import {Dispatch} from 'redux';
import {Action} from '../../lib/context/types';
import {RelevantService} from '../../api/types';
import {BlogContent} from '../../api/getBlogOverviewContent';

interface Props extends BlogContent {
  lazyLoading: boolean;
}
export const BlogItem = ({
  title,
  publishDate,
  imageUrl,
  imageAlt,
  slug,
  relevantServices,
  lazyLoading,
}: Props) => {
  const {t} = useTranslation();
  const language = useSelector(languageSelector);
  const date = new Date(publishDate);
  const dispatch: Dispatch<Action> = useDispatch();

  let formattedDate = date.toLocaleString(language!!, {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  });

  const onBlogItemClick = (services: RelevantService[]) => {
    if (services) {
      services.forEach((service) => {
        dispatch({
          type: 'ADD_OR_UPDATE_INTENT',
          payload: {
            axis: 'state',
            label: 'intent',
            intent: 'interest',
            value: service.id,
            score: 100,
            single: false,
            mutable: true,
            removable: true,
          },
        });
      });
    }
  };

  return (
    <MotionBox
      cursor="pointer"
      sx={{mb: [4, 0]}}
      whileHover={{scale: 1.01}}
      transition={{ease: 'easeInOut'}}>
      <Link href={`/blogs/${slug}`} passHref>
        <ChakraLink
          aria-label={t('goToAltText', {url: `"${title}"`})}
          layerStyle="tile"
          role="group"
          d="block"
          sx={{
            _hover: {
              textDecoration: 'none',
            },
          }}
          onClick={() => onBlogItemClick(relevantServices)}>
          <Image
            loading={lazyLoading ? 'lazy' : 'eager'}
            height={128}
            src={imageUrl}
            alt={imageAlt ?? ''}
            objectFit="cover"
            width="100%"
            borderTopRadius="xl"
            backgroundColor="grey"
          />
          <Box sx={{p: 6}}>
            <Text as="span" sx={{textDecoration: 'none', fontWeight: 'normal'}}>
              {title}
            </Text>
            <Text mb={0}>
              {t('services.published_on', {date: formattedDate})}
            </Text>
          </Box>
        </ChakraLink>
      </Link>
    </MotionBox>
  );
};
