import {Center, Spinner} from '@chakra-ui/react';
import React from 'react';
import {useFaqContent} from '../../api/getFaq';
import {FaqView} from '../Faq/FaqView';
import {FaqWrapper} from '../Faq/FaqWrapper';
import {useCustomerTag} from '../../hooks/useCustomerTag';
import {useTriggerTag} from '../../hooks/useTriggerTag';
import {useLanguageChange} from '../../utils/useLanguageChange';

export const FaqController = () => {
  const customer = useCustomerTag();
  const trigger = useTriggerTag();

  const config: string[] = [customer, trigger];

  const {data, isLoading, refetch} = useFaqContent(config);
  useLanguageChange(refetch);

  return (
    <FaqWrapper>
      {isLoading ? (
        <Center>
          <Spinner
            mt={10}
            emptyColor="gray.200"
            thickness="4px"
            speed="0.7s"
            size="xl"
            color="primary"
          />
        </Center>
      ) : data ? (
        <FaqView {...data} />
      ) : null}
    </FaqWrapper>
  );
};
