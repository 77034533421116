import {Context, Intent, Tag} from '../../lib/context/types';
import {DerivedFaq} from './FaqView';

interface FaqWithContext extends DerivedFaq {
  tag?: Tag;
}

/**
 * Return true if intent A is less than or equal intent B
 */
export const lte = (intentA: Intent, intentB: Intent) => {
  const intentOrder: Intent[] = ['awareness', 'interest', 'decision', 'action'];
  const indexA = intentOrder.findIndex((item) => item === intentA);
  const indexB = intentOrder.findIndex((item) => item === intentB);
  return indexA >= indexB;
};

const getMostValuableTag = (tags: Context, faq: DerivedFaq) => {
  const tagsWithIntent = tags.filter((item) => item.intent !== undefined);
  let mostValuableTag: Tag | undefined = undefined;
  faq.items.forEach((faqitem) => {
    faqitem.relevantServices?.forEach((service) => {
      const tag = tagsWithIntent.find((item) => item.value === service.id);
      if (tag) {
        if (!mostValuableTag) {
          mostValuableTag = tag;
        } else if (mostValuableTag.intent! === tag.intent!) {
          if (tag.score > mostValuableTag.score) {
            mostValuableTag = tag;
          }
        } else if (!lte(mostValuableTag.intent!, tag.intent!)) {
          mostValuableTag = tag;
        }
      }
    });
  });
  return mostValuableTag;
};

export const sortFaqs = (faqs: DerivedFaq[], tags: Context, cleanup = true) => {
  // enrich faqs with intent and score from context, if available
  const enrichedFaqs: FaqWithContext[] = faqs.map((faq) => {
    const tag = getMostValuableTag(tags, faq);
    return {
      ...faq,
      tag,
    };
  });

  // If compareFunction(a, b) returns a value > than 0, sort b before a.
  // If compareFunction(a, b) returns a value ≤ 0, leave a and b in the same order.
  const compareFunction = (faqA: FaqWithContext, faqB: FaqWithContext) => {
    if (!faqA.tag && faqB.tag) {
      // als A geen context heeft, maar B wel dan B prio
      return 1;
    }

    if (!faqB.tag && faqA.tag) {
      // als B geen context heeft, maar A wel dan A prio
      return -1;
    }

    // als beide geen context, sort hen dan by title
    if (!faqA.tag && !faqB.tag) {
      if (faqA.title < faqB.title) {
        return -1;
      } else {
        return 1;
      }
    }

    if (faqA.tag!.intent === faqB.tag!.intent) {
      if (faqA.tag!.score === faqB.tag!.score) {
        // order by title
        if (faqA.title < faqB.title) {
          return -1;
        } else {
          return 1;
        }
      } else {
        // order by score
        if (faqA.tag!.score < faqB.tag!.score) {
          return 1;
        } else {
          return -1;
        }
      }
    }

    // We know intents are not equal.
    // If A has action with score 100, B has prio
    // If B has action with score 100, A has prio
    if (faqA.tag!.intent! === 'action' && faqA.tag!.score === 100) {
      return 1;
    } else if (faqB.tag!.intent! === 'action' && faqB.tag!.score === 100) {
      return -1;
    }

    // order by intent
    if (lte(faqA.tag!.intent!, faqB.tag!.intent!)) {
      return -1;
    } else {
      return 1;
    }
  };

  enrichedFaqs.sort(compareFunction);

  // remove added tag prop
  const result = enrichedFaqs.map((item) => {
    if (cleanup) delete item.tag;
    return item;
  });

  return result;
};
