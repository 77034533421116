import {Box, Flex, Heading, SimpleGrid, Image} from '@chakra-ui/react';
import React from 'react';
import {VisualList} from '../../api/types';
import {parseHtmlString} from '../../utils/parseHtmlString';

interface Props extends VisualList {
  lazyLoading: boolean;
}
export const VisualListView = ({
  title,
  description,
  items,
  lazyLoading,
}: Props) => {
  const columns = [
    1,
    3,
    null,
    items.length === 3 ? 3 : items.length === 5 ? 5 : 4,
  ];

  const customLinkStyles = {
    textColor: 'visualListColor',
  };

  const headingStyles =
    items.length > 3
      ? {
          fontSize: '1.5rem',
          lineHeight: '2rem',
        }
      : {};

  return (
    <Box>
      <Flex direction="column" alignItems="center" textAlign="center">
        <Heading variant="h2">{title}</Heading>
        <Box mb={10}>{parseHtmlString(description, lazyLoading)}</Box>
      </Flex>
      <SimpleGrid columns={columns} spacing={10}>
        {items.map((item, index) => (
          <Flex key={index} flexDirection="column" textAlign="center">
            <Box w={[32, 44]} mb={[2.5, 8]} mx="auto">
              <Image
                width="100%"
                height="100%"
                loading={lazyLoading ? 'lazy' : 'eager'}
                alt=""
                src={item.imageUrl}
              />
            </Box>
            <Heading as="h3" variant="h3" sx={headingStyles}>
              {item.title}
            </Heading>
            <Box textAlign="center" mb={0}>
              {parseHtmlString(
                item.description,
                lazyLoading,
                undefined,
                customLinkStyles,
              )}
            </Box>
          </Flex>
        ))}
      </SimpleGrid>
    </Box>
  );
};
