import React from 'react';
import {ContactController} from '../components/Contact';
import {IntroController} from '../components/Intro';
import {ServicesOverviewController} from '../components/ServicesOverview/ServicesOverviewController';
import {FaqController} from '../components/Faq';
import {HeaderController} from '../components/Header';
import {FooterController} from '../components/Footer';
import {VisualListController} from '../components/VisualList';
import {BlogController} from '../components/Blog';
import {BlogOverviewController} from '../components/BlogOverview/BlogOverviewController';
import {ServiceIndividualController} from '../components/ServiceIndividual';

export const availableContentTypes = [
  'blog',
  'blogOverview',
  'faq',
  'footer',
  'header',
  'contact',
  'intro',
  'serviceOverview',
  'visualList',
  'service',
] as const;

export type ContentType = typeof availableContentTypes[number];

interface Content {
  id: number;
  type: ContentType;
  tags?: string[];
  footerCopyText?: string;
}

// first 2 items will not be lazy loaded, perhaps 3 is better in some cases, will have to decide in the future. Or perhaps make this configurable per page
const shouldLazyLoad = (index: number) => index >= 2;

export const parseContent = (content: Content, index: number) => {
  switch (content.type) {
    case 'blog':
      return (
        <BlogController
          id={content.id as unknown as string}
          tags={content.tags}
          individualItem
          key={index}
          lazyLoading={shouldLazyLoad(index)}
        />
      );
    case 'blogOverview':
      return (
        <BlogOverviewController
          key={index}
          lazyLoading={shouldLazyLoad(index)}
        />
      );
    case 'faq':
      return <FaqController key={index} />;
    case 'footer':
      return (
        <FooterController
          key={index}
          lazyLoading={shouldLazyLoad(index)}
          copyText={content.footerCopyText}
        />
      );
    case 'header':
      return (
        <HeaderController key={index} lazyLoading={shouldLazyLoad(index)} />
      );
    case 'contact':
      return (
        <ContactController key={index} lazyLoading={shouldLazyLoad(index)} />
      );
    case 'intro':
      return (
        <IntroController key={index} lazyLoading={shouldLazyLoad(index)} />
      );
    case 'serviceOverview':
      return (
        <ServicesOverviewController
          key={index}
          lazyLoading={shouldLazyLoad(index)}
        />
      );
    case 'visualList':
      return (
        <VisualListController key={index} lazyLoading={shouldLazyLoad(index)} />
      );
    case 'service':
      return (
        <ServiceIndividualController
          key={index}
          lazyLoading={shouldLazyLoad(index)}
          tags={content.tags}
        />
      );
    default:
      return null;
  }
};
