import {Box, Flex, Select, Text} from '@chakra-ui/react';
import React, {ChangeEvent, Fragment, useEffect, useState} from 'react';
import {ChevronDown} from 'react-feather';
import {useTranslation} from 'react-i18next';
import {MotionBox} from '../MotionBox/MotionBox';
import {DerivedFaq} from './FaqView';

export const Categories = ({
  faqsCategories,
  handleSelect,
  onGroupClick,
  highlightGroupIsActive,
  isHighlightSection = false,
}: {
  faqsCategories: DerivedFaq[];
  isHighlightSection?: boolean;
  highlightGroupIsActive: boolean;
  handleSelect(e: ChangeEvent<HTMLSelectElement>): void;
  onGroupClick(info: {title: string; id: number}): void;
}) => {
  const [selectedValue, setSelectedValue] = useState<string | undefined>();
  const {t} = useTranslation();

  useEffect(() => {
    if (!highlightGroupIsActive && isHighlightSection) {
      setSelectedValue('default');
    } else {
      setSelectedValue(
        faqsCategories
          ? faqsCategories.find((faq) => faq.isOpen)?.title
          : undefined,
      );
    }
  }, [faqsCategories, highlightGroupIsActive, isHighlightSection]);

  const isOpenState = (isOpen: boolean) => {
    return (
      (isOpen && highlightGroupIsActive && isHighlightSection) ||
      (isOpen && !highlightGroupIsActive && !isHighlightSection)
    );
  };

  return (
    <>
      <Select
        {...selectStyle}
        icon={
          <Flex alignItems="center" marginLeft="-6">
            <ChevronDown size={24} />
          </Flex>
        }
        value={selectedValue}
        onChange={handleSelect}>
        {isHighlightSection && (
          <option key="default" value="default">
            {t('faqs.select')}
          </option>
        )}
        {faqsCategories.map((category, index) => (
          <option key={`${category.title}-${index}`} value={category.title}>
            {category.title}
          </option>
        ))}
      </Select>
      {faqsCategories.map((category, index) => {
        return (
          <Fragment key={`${category.title}-${index}`}>
            <MotionBox
              {...faqCategoriesBoxStyle}
              onClick={() => {
                onGroupClick({title: category.title, id: index});
              }}
              width="100%"
              opacity={isOpenState(category.isOpen) ? undefined : 0.45}
              boxShadow={
                isOpenState(category.isOpen) ? '3px 23px 28px -26px' : undefined
              }
              background="linear-gradient(90deg, #FFFFFF 0%, transparent 100%)"
              data-cy={'FaqCategory' + category.title}>
              <Box height="100%" paddingY="3">
                <Box
                  ml="2px"
                  width="1px"
                  height="100%"
                  backgroundColor={
                    isOpenState(category.isOpen) ? 'selectedColor' : undefined
                  }
                />
              </Box>
              <Text fontWeight="normal" marginX={7} mb={0}>
                {category.title}
              </Text>
            </MotionBox>
          </Fragment>
        );
      })}
    </>
  );
};

const selectStyle = {
  cursor: 'pointer',
  boxShadow: '0px 24px 34px -13px rgba(0, 39, 118, 0.13)',
  iconSize: '32',
  backgroundColor: 'faqBackground',
  height: 14,
  variant: 'filled',
  display: [null, null, 'none', 'none'],
  iconColor: 'faqIconColor',
  size: 'lg',
};

const faqCategoriesBoxStyle = {
  cursor: 'pointer',
  whileHover: {scale: 1.05},
  whileTap: {scale: 0.9},
  height: '14',
  display: ['none', 'none', 'flex'],
  alignItems: 'center',
  mb: '1',
  borderRadius: 12,
};
