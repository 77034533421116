import {Box, Heading} from '@chakra-ui/react';
import React, {ChangeEvent} from 'react';
import {useTranslation} from 'react-i18next';
import {Categories} from './Categories';
import {DerivedFaq} from './FaqView';

export const CategorySection = ({
  handleSelect,
  onGroupClick,
  highlightGroupIsActive,
  highlightedDerivedFaqs,
  derivedFaqs,
  onHighlightedGroupClick,
  handleHighlightSelect,
}: {
  highlightedDerivedFaqs: DerivedFaq[];
  derivedFaqs: DerivedFaq[];
  highlightGroupIsActive: boolean;
  handleHighlightSelect(e: ChangeEvent<HTMLSelectElement>): void;
  handleSelect(e: ChangeEvent<HTMLSelectElement>): void;
  onHighlightedGroupClick(info: {title: string; id: number}): void;
  onGroupClick(info: {title: string; id: number}): void;
}) => {
  const {t} = useTranslation();
  return (
    <Box mb={[10, null, 0]} mr={[0, 0, 9]}>
      {highlightedDerivedFaqs.length > 0 && (
        <Box mb={14}>
          <Heading as="h4" variant="h4">
            {t('highlighted_for_you')}
          </Heading>
          <Box mb={[10, null, 0]} mr={[0, 0, 9]}>
            <Categories
              faqsCategories={highlightedDerivedFaqs}
              onGroupClick={onHighlightedGroupClick}
              handleSelect={handleHighlightSelect}
              highlightGroupIsActive={highlightGroupIsActive}
              isHighlightSection
            />
          </Box>
        </Box>
      )}
      {highlightedDerivedFaqs.length > 0 && (
        <Heading as="h4" variant="h4">
          {t('everything')}
        </Heading>
      )}
      {derivedFaqs.length <= 1 ? null : (
        <Categories
          highlightGroupIsActive={highlightGroupIsActive}
          faqsCategories={derivedFaqs}
          onGroupClick={onGroupClick}
          handleSelect={handleSelect}
        />
      )}
    </Box>
  );
};
