import {Context, Intent, Tag} from '../../lib/context/types';
import {Service} from '../Service';

interface ServiceWithContext extends Service {
  tag?: Tag;
}

/**
 * Return true if intent A is less than or equal intent B
 */
export const lte = (intentA: Intent, intentB: Intent) => {
  const intentOrder: Intent[] = ['awareness', 'interest', 'decision', 'action'];
  const indexA = intentOrder.findIndex((item) => item === intentA);
  const indexB = intentOrder.findIndex((item) => item === intentB);
  return indexA >= indexB;
};

export const sortServices = (
  services: Service[],
  tags: Context,
  cleanup = true,
) => {
  const tagsWithIntent = tags.filter((item) => item.intent !== undefined);

  // enrich services with intent and score from context, if available
  const enrichedServices: ServiceWithContext[] = services.map((service) => {
    const tag = tagsWithIntent.find((item) => item.value === service.id);
    return {
      ...service,
      tag,
    };
  });

  // If compareFunction(a, b) returns a value > than 0, sort b before a.
  // If compareFunction(a, b) returns a value ≤ 0, leave a and b in the same order.
  const compareFunction = (
    serviceA: ServiceWithContext,
    serviceB: ServiceWithContext,
  ) => {
    if (!serviceA.tag && serviceB.tag) {
      // als A geen context heeft, maar B wel dan B prio
      return 1;
    }

    if (!serviceB.tag && serviceA.tag) {
      // als B geen context heeft, maar A wel dan A prio
      return -1;
    }

    // als beide geen context, sort hen dan by title
    if (!serviceA.tag && !serviceB.tag) {
      if (serviceA.title < serviceB.title) {
        return -1;
      } else {
        return 1;
      }
    }

    if (serviceA.tag!.intent === serviceB.tag!.intent) {
      if (serviceA.tag!.score === serviceB.tag!.score) {
        // order by title
        if (serviceA.title < serviceB.title) {
          return -1;
        } else {
          return 1;
        }
      } else {
        // order by score
        if (serviceA.tag!.score < serviceB.tag!.score) {
          return 1;
        } else {
          return -1;
        }
      }
    }

    // We know intents are not equal.
    // If A has action with score 100, B has prio
    // If B has action with score 100, A has prio
    if (serviceA.tag!.intent! === 'action' && serviceA.tag!.score === 100) {
      return 1;
    } else if (
      serviceB.tag!.intent! === 'action' &&
      serviceB.tag!.score === 100
    ) {
      return -1;
    }

    // order by intent
    if (lte(serviceA.tag!.intent!, serviceB.tag!.intent!)) {
      return -1;
    } else {
      return 1;
    }
  };

  enrichedServices.sort(compareFunction);

  // remove added tag prop
  const result = enrichedServices.map((item) => {
    if (cleanup) delete item.tag;
    return item;
  });

  return result;
};
