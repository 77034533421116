import getConfig from 'next/config';
import {useQuery} from 'react-query';
import {useSelector} from 'react-redux';
import {languageSelector} from '../lib/context/selectors';
import {fapper} from '../utils/fapper';
import {ContentResponse} from './types';

type Intro = {
  title: string;
  description: string;
  imageUrl: string;
};

const {publicRuntimeConfig} = getConfig();

const getIntroContent = async (
  tagParams: string[],
  language: string,
): Promise<Intro> => {
  const result: ContentResponse<Intro> = await fapper.get(
    `${
      publicRuntimeConfig.NEXT_PUBLIC_UMBRACO_API
    }?culture=${language}&tags=${tagParams.join(';')}&type=introduction`,
  );
  return result.contentItems[0];
};

const useIntroContent = (tagParams: string[]) => {
  const language = useSelector(languageSelector);
  return useQuery<Intro>(
    ['intro'],
    () => getIntroContent(tagParams, language!!),
    {
      refetchOnWindowFocus: false,
    },
  );
};

export {useIntroContent, getIntroContent};
