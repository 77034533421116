import getConfig from 'next/config';
import {QueryKey, useQuery, UseQueryOptions} from 'react-query';
import {useSelector} from 'react-redux';
import {languageSelector} from '../lib/context/selectors';
import {fapper} from '../utils/fapper';
import {RelevantService} from './../api/types';

export interface BlogResponse {
  contentItems: BlogProps[];
}
export interface BlogProps {
  title: string;
  contentItems: BlogContent[];
}

export interface BlogContent {
  imageUrl: string;
  imageAlt: string;
  publishDate: string;
  content?: string;
  title: string;
  slug: string;
  relevantServices: RelevantService[];
}

const {publicRuntimeConfig} = getConfig();

const getBlogOverviewContent = async (
  tags: string[],
  language: string,
): Promise<BlogProps> => {
  const tagParam = tags.join(';');
  const result: BlogResponse = await fapper.get(
    `${publicRuntimeConfig.NEXT_PUBLIC_UMBRACO_API}?culture=${language}&tags=${tagParam}&type=blogoverview`,
  );
  return result.contentItems[0];
};

const useBlogOverviewContent = (
  tags: string[],
  options: UseQueryOptions<BlogProps, unknown, BlogProps, QueryKey> = {
    refetchOnWindowFocus: false,
  },
) => {
  const language = useSelector(languageSelector);
  return useQuery<BlogProps>(
    ['BlogContent', tags],
    () => getBlogOverviewContent(tags, language!!),
    options,
  );
};

export {useBlogOverviewContent, getBlogOverviewContent};
