import {SimpleGrid, Box} from '@chakra-ui/react';
import {Service} from './ServiceView';
import {ServiceController} from './ServiceController';

interface ServicesProps {
  services: Service[];
  lazyLoading: boolean;
}

export const ServicesView = ({services, lazyLoading}: ServicesProps) => {
  return (
    <>
      <SimpleGrid
        columns={[1, 2, 2, 3]}
        spacing={[0, 7]}
        data-cy={'ServicesView'}>
        {services.map((service, i) => (
          <Box cursor="pointer" key={i} sx={{mb: [4, 0]}}>
            <ServiceController lazyLoading={lazyLoading} service={service} />
          </Box>
        ))}
      </SimpleGrid>
    </>
  );
};
