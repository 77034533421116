import React from 'react';
import {Box} from '@chakra-ui/react';
import {Main} from '../_shared/Layout/Main';

interface Props {
  children: React.ReactNode | React.ReactNode[];
}
export const FaqWrapper = ({children}: Props) => {
  return (
    <Box as="section" py={[6, 28]}>
      <Main>{children}</Main>
    </Box>
  );
};
