import React from 'react';
import {useContactContent} from '../../api/getContact';
import {ContactView} from './ContactView';
import {Spinner, Center} from '@chakra-ui/react';
import {ContactWrapper} from './ContactWrapper';
import {useLanguageChange} from '../../utils/useLanguageChange';
import {useContentConfig} from '../../utils/useContentConfig';
import {Main} from '../_shared/Layout/Main';

interface Props {
  lazyLoading: boolean;
}

export const ContactController = ({lazyLoading}: Props) => {
  const config = useContentConfig();
  const {data, isLoading, isFetching, refetch} = useContactContent(config);
  useLanguageChange(refetch);

  return (
    <ContactWrapper>
      {isLoading || isFetching ? (
        <Main>
          <Center pb={[10, 14]}>
            <Spinner
              emptyColor="gray.200"
              thickness="4px"
              speed="0.7s"
              size="xl"
              color="primary"
            />
          </Center>
        </Main>
      ) : data ? (
        <ContactView lazyLoading={lazyLoading} {...data} />
      ) : null}
    </ContactWrapper>
  );
};
