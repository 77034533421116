import React from 'react';
import {useVisualListContent} from '../../api/getVisualListContent';
import {Spinner, Center} from '@chakra-ui/react';
import {VisualListView} from './VisualListView';
import {ComponentWrapper} from '../ComponentWrapper';
import {useLanguageChange} from '../../utils/useLanguageChange';
import {useCustomerTag} from '../../hooks/useCustomerTag';
import {useTriggerTag} from '../../hooks/useTriggerTag';

interface Props {
  lazyLoading: boolean;
}

export const VisualListController = ({lazyLoading}: Props) => {
  const customer = useCustomerTag();
  const trigger = useTriggerTag();

  const config: string[] = [customer, trigger];
  const {data, isLoading, isFetching, refetch} = useVisualListContent(config);

  useLanguageChange(refetch);

  return (
    <ComponentWrapper
      as="section"
      bg="visualListBackground"
      color="visualListColor"
      py={[16, 28]}>
      {isLoading || isFetching ? (
        <Center>
          <Spinner
            emptyColor="gray.200"
            thickness="4px"
            speed="0.7s"
            size="xl"
            color="primary"
          />
        </Center>
      ) : data ? (
        <VisualListView
          lazyLoading={lazyLoading}
          {...data}
          items={data.items || []}
        />
      ) : null}
    </ComponentWrapper>
  );
};
