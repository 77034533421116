import {Box} from '@chakra-ui/layout';
import React from 'react';
import {ServicesOverviewView} from './ServicesOverviewView';
import {useServicesContent} from '../../api/getServices';
import {Spinner, Center} from '@chakra-ui/react';
import {useSelector} from 'react-redux';
import {
  contextSelector,
  valuesOfTagsWithIntent,
} from '../../lib/context/selectors';
import {useLanguageChange} from '../../utils/useLanguageChange';
import {useCustomerTag} from '../../hooks/useCustomerTag';
import {useTriggerTag} from '../../hooks/useTriggerTag';
import {sortServices} from './sortServices';

interface Props {
  lazyLoading: boolean;
}

export const ServicesOverviewController = ({lazyLoading}: Props) => {
  const customer = useCustomerTag();
  const trigger = useTriggerTag();

  const {data, isLoading, isFetching, refetch} = useServicesContent([
    customer,
    trigger,
  ]);

  useLanguageChange(refetch);

  const allTags = useSelector(contextSelector);
  const serviceIdsInterestDecision = useSelector(
    valuesOfTagsWithIntent(['interest', 'decision']),
  );

  const serviceItems = data ? data.items : [];
  const sortedServiceItems = sortServices(serviceItems, allTags, false);

  const prominentServices = serviceItems.filter((service) =>
    serviceIdsInterestDecision.includes(service.id),
  );
  const sortedProminentServices = sortServices(prominentServices, allTags);

  return (
    <Box as="section" mb={[12, null, 14]} pt={[8, 14]} pb={[8, 7]}>
      {isLoading || isFetching ? (
        <Center>
          <Spinner
            mt={10}
            emptyColor="gray.200"
            thickness="4px"
            speed="0.7s"
            size="xl"
            color="primary"
          />
        </Center>
      ) : data ? (
        <ServicesOverviewView
          lazyLoading={lazyLoading}
          title={data.title}
          services={sortedServiceItems}
          prominentServices={sortedProminentServices}
        />
      ) : null}
    </Box>
  );
};
