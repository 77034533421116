import {QueryKey, useQuery, UseQueryOptions} from 'react-query';
import {useSelector} from 'react-redux';
import {languageSelector} from '../lib/context/selectors';
import {Service} from '../components/Service/ServiceView';
import {fapper} from '../utils/fapper';
import getConfig from 'next/config';

export interface ServicesResponse {
  contentItems: ServiceOverview[];
}
export interface ServiceOverview {
  title: string;
  items: Service[];
}

const {publicRuntimeConfig} = getConfig();

const getServicesContent = async (
  tags: string[],
  language: string,
): Promise<ServiceOverview> => {
  const tagParam = tags.join(';');
  const result: ServicesResponse = await fapper.get(
    `${publicRuntimeConfig.NEXT_PUBLIC_UMBRACO_API}?culture=${language}&tags=${tagParam}&type=serviceoverview`,
  );
  return result.contentItems[0];
};

const useServicesContent = (
  tags: string[],
  options: UseQueryOptions<
    ServiceOverview,
    unknown,
    ServiceOverview,
    QueryKey
  > = {
    refetchOnWindowFocus: false,
  },
) => {
  const language = useSelector(languageSelector);
  return useQuery<ServiceOverview>(
    ['ServiceContent', tags],
    () => getServicesContent(tags, language!!),
    options,
  );
};

export {useServicesContent, getServicesContent};
