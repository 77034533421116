import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Heading,
  useTheme,
} from '@chakra-ui/react';
import React from 'react';
import {DefaultTheme} from '../../theme/themes/default';
import {parseHtmlString} from '../../utils/parseHtmlString';
import {DerivedFaq} from './FaqView';

export const FaqSection = ({
  derivedFaqs,
  onFaqItemClick,
  lazyLoading,
}: {
  derivedFaqs: DerivedFaq[];
  onFaqItemClick(index: number, setIndex: number, id: number): void;
  lazyLoading: boolean;
}) => {
  const theme = useTheme<DefaultTheme>();
  let defaultOpenIndex = -1;

  function getDefaultOpenIndex() {
    derivedFaqs.forEach((faqSet) => {
      faqSet.items.forEach((faq, index) => {
        if (faq.isOpen) {
          defaultOpenIndex = index;
        }
      });
    });
  }

  getDefaultOpenIndex();
  return (
    <>
      {derivedFaqs.map((set, setIndex) => (
        <React.Fragment key={`${set.title}-${setIndex}`}>
          {set.isOpen && (
            <Accordion
              mb={[10, null, 0]}
              defaultIndex={
                defaultOpenIndex !== -1 ? defaultOpenIndex : undefined
              }
              allowToggle>
              {derivedFaqs
                .find((faq) => faq.isOpen)
                ?.items.map((faq, index, arr) => {
                  return (
                    <AccordionItem
                      key={`${set.title}-${index}`}
                      borderTop="none"
                      borderColor="transparent"
                      boxShadow={
                        index === arr.length - 1
                          ? ' 0px 24px 34px -13px rgba(0, 39, 118, 0.13)'
                          : undefined
                      }
                      mb="1"
                      data-cy={'FaqItem' + faq?.id}>
                      {({isExpanded}) => (
                        <React.Fragment>
                          <Heading
                            {...headingStyle}
                            as="h3"
                            onClick={() => {
                              onFaqItemClick(index, setIndex, faq.id);
                            }}>
                            <AccordionButton
                              {...accordionButtonStyle}
                              sx={{textAlign: 'left'}}
                              borderBottomRadius={
                                index === arr.length - 1 && !isExpanded ? 12 : 0
                              }
                              borderTopRadius={index === 0 ? 12 : 0}>
                              {faq.question}
                              <AccordionIcon
                                height={7}
                                width={7}
                                color={theme.colors.faqIconColor}
                              />
                            </AccordionButton>
                          </Heading>
                          <AccordionPanel
                            {...accordionPanelStyle}
                            borderBottomRadius={
                              index === arr.length - 1 ? 12 : 0
                            }>
                            {parseHtmlString(faq.answer, lazyLoading)}
                          </AccordionPanel>
                        </React.Fragment>
                      )}
                    </AccordionItem>
                  );
                })}
            </Accordion>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

const accordionButtonStyle = {
  justifyContent: 'space-between',
  padding: 6,
  backgroundColor: 'faqBackground',
  _hover: {
    backgroundColor: 'faqBackgroundHover',
  },
};

const accordionPanelStyle = {
  backgroundColor: 'faqBackground',
  pt: 5,
  paddingX: '6',
};

const headingStyle = {
  mb: 0,
  variant: 'h3',
  cursor: 'pointer',
  display: 'flex',
};
