import React from 'react';
import {BlogOverviewView} from './BlogOverviewView';
import {useBlogOverviewContent} from '../../api/getBlogOverviewContent';
import {Spinner, Center} from '@chakra-ui/react';
import {BlogOverviewWrapper} from './BlogOverviewWrapper';
import {useLanguageChange} from '../../utils/useLanguageChange';
import {useCustomerTag} from '../../hooks/useCustomerTag';
import {useTriggerTag} from '../../hooks/useTriggerTag';
import {useSelector} from 'react-redux';
import {
  contextSelector,
  valuesOfTagsWithIntent,
} from '../../lib/context/selectors';
import {sortBlogs} from './sortBlogs';

interface Props {
  lazyLoading: boolean;
}

export const BlogOverviewController = ({lazyLoading}: Props) => {
  const customer = useCustomerTag();
  const trigger = useTriggerTag();
  const blogIdsInterestDecision = useSelector(
    valuesOfTagsWithIntent(['interest', 'decision']),
  );
  const allTags = useSelector(contextSelector);

  const config: string[] = [customer, trigger];
  const {data, isLoading, isFetching, refetch} = useBlogOverviewContent(config);

  const blogItems = data?.contentItems || [];
  const sortedBlogItems = sortBlogs(blogItems, allTags, false);

  const prominentBlogs =
    data?.contentItems.filter((blog) =>
      blog.relevantServices?.some((service) =>
        blogIdsInterestDecision.includes(service.id),
      ),
    ) || [];

  const sortedProminentBlogs = sortBlogs(prominentBlogs, allTags, false);

  useLanguageChange(refetch);

  if (isLoading || isFetching) {
    return (
      <BlogOverviewWrapper>
        <Center>
          <Spinner
            mt={10}
            emptyColor="gray.200"
            thickness="4px"
            speed="0.7s"
            size="xl"
            color="primary"
          />
        </Center>
      </BlogOverviewWrapper>
    );
  }

  if (data && data.contentItems?.length > 0) {
    return (
      <BlogOverviewView
        lazyLoading={lazyLoading}
        title={data?.title ?? ''}
        blogs={sortedBlogItems}
        prominentBlogs={sortedProminentBlogs}
      />
    );
  }

  return null;
};
