import getConfig from 'next/config';
import {QueryKey, useQuery, UseQueryOptions} from 'react-query';
import {useSelector} from 'react-redux';
import {languageSelector} from '../lib/context/selectors';
import {fapper} from '../utils/fapper';
import {Contact, ContentResponse} from './types';

const {publicRuntimeConfig} = getConfig();

const getContactContent = async (
  tagParams: string[],
  language: string,
): Promise<Contact> => {
  const result: ContentResponse<Contact> = await fapper.get(
    `${
      publicRuntimeConfig.NEXT_PUBLIC_UMBRACO_API
    }?culture=${language}&tags=${tagParams.join(';')}&type=contact`,
  );
  return result.contentItems[0];
};

const useContactContent = (
  tagParams: string[],
  options: UseQueryOptions<Contact, unknown, Contact, QueryKey> = {
    refetchOnWindowFocus: false,
  },
) => {
  const language = useSelector(languageSelector);
  return useQuery<Contact>(
    ['contact'],
    () => getContactContent(tagParams, language!!),
    options,
  );
};

export {useContactContent, getContactContent};
