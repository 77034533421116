import {
  Box,
  Text,
  Heading,
  SimpleGrid,
  Link,
  Flex,
  Image,
} from '@chakra-ui/react';
import React from 'react';
import {fontWeights} from '../../theme/themes/default';
import {Mail, MapPin, Phone} from 'react-feather';
import {Contact} from '../../api/types';
import {useTranslation} from 'react-i18next';
import {HtmlContent} from '../_shared/HtmlContent';
import {KhsLink} from '../_shared/KhsLink';
import {ArrowForwardIcon} from '@chakra-ui/icons';
import {Main} from '../_shared/Layout/Main';

interface Props extends Contact {
  lazyLoading: boolean;
}
export const ContactView = ({
  title,
  phone,
  email,
  visit,
  lazyLoading,
}: Props) => {
  const {t} = useTranslation();
  return (
    <>
      <Main>
        <Box pb={[5, 7]}>
          <Heading as="h2" variant="h2" mb={10}>
            {title}
          </Heading>
          <SimpleGrid columns={{sm: 1, md: 3}} data-cy="contact">
            <Box mb={[10, null, 0]}>
              <Box>
                <Heading as="h3" variant="h3">
                  <Flex align="center">
                    <Box mr={5}>
                      <Phone size={20} />
                    </Box>
                    {t('contact.call')}
                  </Flex>
                </Heading>
                <Box ml="10">
                  <Link
                    href={`tel:${phone.number}`}
                    isExternal
                    fontWeight={fontWeights.normal}>
                    {phone.title} <ArrowForwardIcon />
                  </Link>
                  <Box w={{sm: '100%', md: '230px'}} mt={4}>
                    <HtmlContent
                      data={phone.description}
                      lazyLoading={lazyLoading}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box mb={[10, null, 0]}>
              <Box>
                <Heading as="h3" variant="h3">
                  <Flex align="center">
                    <Box mr={5}>
                      <Mail size={20} />
                    </Box>
                    {email.title}
                  </Flex>
                </Heading>
                <Box ml="10">
                  <Link
                    href={`mailto:${email.email}?subject=${email.subject}`}
                    fontWeight={fontWeights.normal}>
                    {email.email} <ArrowForwardIcon />
                  </Link>
                </Box>
              </Box>
            </Box>
            <Box>
              <Box>
                <Heading as="h3" variant="h3">
                  <Flex align="center">
                    <Box mr={5}>
                      <MapPin size={20} />
                    </Box>
                    {visit.title}
                  </Flex>
                </Heading>
                <Box ml="10">
                  <Text
                    fontWeight={fontWeights.thin}
                    whiteSpace="nowrap"
                    mb={0}
                    mr={6}
                    dangerouslySetInnerHTML={{
                      __html: visit.address.replace(
                        /(?:\r\n|\r|\n)/g,
                        '<br />',
                      ),
                    }}
                  />
                  <KhsLink
                    href={visit.link}
                    title="Google maps"
                    aria-label="Google maps"
                    textColor="white"
                    isExternal
                  />
                </Box>
              </Box>
            </Box>
          </SimpleGrid>
        </Box>
      </Main>
      {visit.imageUrl && (
        <Box>
          <Image
            loading={lazyLoading ? 'lazy' : 'eager'}
            data-cy="contact-image"
            src={visit.imageUrl}
            alt={visit.imageAlt}
            sx={{
              objectFit: 'cover',
              height: ['48px', '96px'],
              width: '100%',
            }}
          />
        </Box>
      )}
    </>
  );
};
