import {Heading, SimpleGrid} from '@chakra-ui/react';
import React, {ChangeEvent, useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Dispatch} from 'redux';
import {Action} from '../../lib/context/types';
import {contextSelector} from '../../lib/context/selectors';
import {sortFaqs} from './sortFaqs';
import {CategorySection} from './CategorySection';
import {FaqSection} from './FaqSection';
import {RelevantService} from './../../api/types';
import {trackEvent} from '../../utils/tracking';

export type FaqItem = {
  id: number;
  question: string;
  answer: string;
  tags: string[];
  relevantServices: RelevantService[];
};
export interface FaqProps {
  title: string;
  sets: FaqSet[];
  lazyLoading: boolean;
}

export type FaqSet = {
  title: string;
  items: FaqItem[];
};

interface DerivedFaqItem extends FaqItem {
  isOpen: boolean;
  highlighted?: boolean;
}

export interface DerivedFaq {
  title: string;
  items: DerivedFaqItem[];
  isOpen: boolean;
}

export const FaqView = ({title, sets, lazyLoading}: FaqProps) => {
  const [derivedFaqs, setDerivedFaqs] = useState<DerivedFaq[]>([]);
  const [highlightedDerivedFaqs, setHighlightedDerivedFaqs] = useState<
    DerivedFaq[]
  >([]);
  const [highlightGroupIsActive, setHighlightGroupIsActive] = useState(false);
  const [openCategory, setOpenCategory] = useState<string | null>(null);
  const [openFaqItem, setOpenFaqItem] = useState(-1);
  const [triggerDispatch, setTriggerDispatch] = useState<RelevantService[]>([]);
  const dispatch: Dispatch<Action> = useDispatch();
  const context = useSelector(contextSelector);

  const sortFaqSet = useCallback(
    (faqs: DerivedFaq[]) => sortFaqs(faqs, context, false),
    [context],
  );

  useEffect(() => {
    if (triggerDispatch.length > 0) {
      triggerDispatch.forEach((service) => {
        dispatch({
          type: 'ADD_OR_UPDATE_INTENT',
          payload: {
            axis: 'state',
            label: 'intent',
            intent: 'interest',
            value: service.id,
            score: 100,
            single: false,
            mutable: true,
            removable: true,
          },
        });
      });
      setTriggerDispatch([]);
    }
  }, [dispatch, triggerDispatch]);

  useEffect(() => {
    const filteredTagsWithIntentInterestOrDecision = context.filter((item) => {
      return (
        item.label === 'intent' && (item.intent === 'interest' || 'decision')
      );
    });

    const tagsWithIntentInterestOrDecision =
      filteredTagsWithIntentInterestOrDecision.length > 0
        ? filteredTagsWithIntentInterestOrDecision
            .map((item) => item.value.toString())
            .filter((v, i, a) => a.indexOf(v) === i) // only unique
        : [];

    const mappedFaqSets = sets.map((category, index) => ({
      title: category.title,
      isOpen:
        category.title === openCategory ||
        (openCategory === null && index === 0),
      items: category.items.map((item) => {
        return {
          ...item,
          isOpen: item.id === openFaqItem,
        };
      }),
    }));
    setDerivedFaqs(sortFaqSet(mappedFaqSets));

    const filterdHighlightedFaqSets = mappedFaqSets.filter((faq) =>
      faq.items?.some(
        (faqItem) =>
          faqItem.relevantServices &&
          faqItem.relevantServices?.some((service) =>
            tagsWithIntentInterestOrDecision.includes(service.id.toString()),
          ),
      ),
    );
    setHighlightedDerivedFaqs(sortFaqSet(filterdHighlightedFaqSets));
  }, [
    sets,
    setDerivedFaqs,
    context,
    setHighlightedDerivedFaqs,
    sortFaqSet,
    openCategory,
    openFaqItem,
  ]);

  const onGroupClick = (info: {title: string; id: number}) => {
    setHighlightGroupIsActive(false);
    setDerivedFaqs((previousFaqs) =>
      sortFaqSet(
        previousFaqs.map((category, index) => {
          if (index === info.id) {
            setOpenCategory(category.title);
          }
          return {
            ...category,
            isOpen: index === info.id,
            items: category.items.map((item) => ({
              ...item,
              isOpen: false,
            })),
          };
        }),
      ),
    );
    trackEvent('FAQ category', 'click', info.title);
  };

  const onHighlightedGroupClick = (info: {title: string; id: number}) => {
    setHighlightGroupIsActive(true);
    setDerivedFaqs((previousFaqs) =>
      sortFaqSet(
        previousFaqs.map((category) => {
          return {
            ...category,
            isOpen: category.title === info.title,
            items: category.items.map((item) => ({
              ...item,
              isOpen: false,
            })),
          };
        }),
      ),
    );
    setHighlightedDerivedFaqs((previousFaqs) =>
      sortFaqSet(
        previousFaqs.map((category, index) => {
          if (index === info.id) {
            setOpenCategory(category.title);
          }
          return {
            ...category,
            isOpen: category.title === info.title,
            items: category.items.map((item) => ({
              ...item,
              isOpen: false,
            })),
          };
        }),
      ),
    );
  };

  const onFaqItemClick = (id: number, setIndex: number, faqId: number) => {
    if (faqId === openFaqItem) {
      setOpenFaqItem(-1);
    } else {
      setOpenFaqItem(faqId);
    }
    setDerivedFaqs((previousFaqs) =>
      sortFaqSet(
        previousFaqs.map((category, categoryIndex) => ({
          ...category,
          items: category.items.map((item, itemIndex) => {
            if (categoryIndex === setIndex && itemIndex === id) {
              const hasState = context.filter((s) => {
                return (
                  s.axis === 'state' &&
                  s.label === 'intent' &&
                  s.intent === 'interest' &&
                  item.relevantServices?.some(
                    (service) => service.id.toString() === s.value,
                  )
                );
              });
              if (item.relevantServices?.length > 0 && hasState.length === 0) {
                setHighlightedDerivedFaqs((prevFaqs) =>
                  sortFaqSet([
                    ...prevFaqs,
                    {
                      ...category,
                      isOpen: false,
                    },
                  ]),
                );
              }

              if (!item.relevantServices) {
                console.log(
                  'no relevant service configured for faq: ',
                  item.question,
                );
              }
              if (item.relevantServices && hasState.length === 0) {
                setTriggerDispatch(item.relevantServices);
              }

              trackEvent('FAQ item', 'click', item.question);
            }

            return {
              ...item,
              isOpen: item.id === openFaqItem,
            };
          }),
        })),
      ),
    );
  };

  const handleSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    setHighlightGroupIsActive(false);
    setDerivedFaqs((previousFaqs) =>
      sortFaqSet(
        previousFaqs.map((category) => ({
          ...category,
          isOpen: category.title === e.target.value,
        })),
      ),
    );
  };
  const handleHighlightSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    setHighlightGroupIsActive(true);
    setHighlightedDerivedFaqs((previousFaqs) =>
      sortFaqSet(
        previousFaqs.map((category) => ({
          ...category,
          isOpen: category.title === e.target.value,
        })),
      ),
    );
    setDerivedFaqs((previousFaqs) =>
      sortFaqSet(
        previousFaqs.map((category) => ({
          ...category,
          isOpen: category.title === e.target.value,
        })),
      ),
    );
  };

  return (
    <>
      <Heading as="h2" variant="h2" color="faqHeadingColor">
        {title}
      </Heading>
      <SimpleGrid
        columns={derivedFaqs.length > 1 ? {sm: 1, md: 2} : {sm: 1}}
        data-cy={'FaqView'}>
        <CategorySection
          derivedFaqs={derivedFaqs}
          highlightGroupIsActive={highlightGroupIsActive}
          highlightedDerivedFaqs={highlightedDerivedFaqs}
          handleHighlightSelect={handleHighlightSelect}
          handleSelect={handleSelect}
          onGroupClick={onGroupClick}
          onHighlightedGroupClick={onHighlightedGroupClick}
        />
        <FaqSection
          lazyLoading={lazyLoading}
          derivedFaqs={derivedFaqs}
          onFaqItemClick={onFaqItemClick}
        />
      </SimpleGrid>
    </>
  );
};
