import {InitialStateLoader} from '../../lib/context/InitialStateLoader';
import {HtmlHead} from '../../theme/components/HtmlHead';
import {ContentType, parseContent} from '../../utils/parseContent';

export type bodyElement = {
  id: number;
  type: ContentType;
  tags?: string[];
  footerCopyText?: string;
};

export interface SlugProp {
  id: number;
  type: string;
  title: string;
  theme?: string;
  keywords?: string[];
  description?: string;
  indexable?: boolean;
  bodyElements: bodyElement[];
  favicon?: string;
}
export interface SlugProps {
  data: SlugProp;
}

export const Slug = ({data}: SlugProps) => {
  if (!data.bodyElements) {
    return null;
  }

  return (
    <InitialStateLoader>
      <HtmlHead
        title={data?.title ?? ''}
        keywords={
          data.keywords
            ? {key: `keywords-${data.id}`, content: data.keywords}
            : undefined
        }
        description={
          data.description
            ? {key: `description-${data.id}`, content: data.description}
            : undefined
        }
        favicon={data.favicon ? data.favicon : undefined}
        indexable={data.indexable}
      />
      {data.bodyElements.map((item, index) =>
        parseContent(
          {
            id: item.id,
            type: item.type,
            tags: item.tags,
            footerCopyText: item.footerCopyText,
          },
          index,
        ),
      )}
    </InitialStateLoader>
  );
};
