import React from 'react';
import {useIntroContent} from '../../api/getIntro';
import {IntroView} from './IntroView';
import {Spinner, Center} from '@chakra-ui/react';
import {ComponentWrapper} from '../ComponentWrapper';
import {useLanguageChange} from '../../utils/useLanguageChange';
import {useContentConfig} from '../../utils/useContentConfig';

interface Props {
  lazyLoading: boolean;
}

export const IntroController = ({lazyLoading}: Props) => {
  const config = useContentConfig();

  const {data, isLoading, isFetching, refetch} = useIntroContent(config);
  useLanguageChange(refetch);

  return (
    <>
      {isLoading || isFetching ? (
        <ComponentWrapper as="section" color="introColor" pl={1}>
          <Center>
            <Spinner
              mt={10}
              emptyColor="gray.200"
              thickness="4px"
              speed="0.7s"
              size="xl"
              color="primary"
            />
          </Center>
        </ComponentWrapper>
      ) : data ? (
        <IntroView lazyLoading={lazyLoading} {...data} />
      ) : null}
    </>
  );
};
