import {Heading, Box} from '@chakra-ui/layout';
import React from 'react';
import {Main} from '../_shared/Layout/Main';
import {Service, ServicesView} from '../Service';
import {useTranslation} from 'react-i18next';
interface Props {
  title: string;
  services: Service[];
  prominentServices: Service[];
  lazyLoading: boolean;
}

export const ServicesOverviewView = ({
  title,
  services,
  prominentServices,
  lazyLoading,
}: Props) => {
  const {t} = useTranslation();

  return (
    <Main>
      <Heading
        as="h2"
        variant="h2"
        mb={10}
        fontWeight="normal"
        color="servicesOverviewHeadingColor">
        {title}
      </Heading>
      {prominentServices.length > 0 && (
        <Box marginBottom={12} data-cy="ProminentServicesView">
          <Heading as="h4" variant="h4" color="servicesOverviewHeadingColor">
            {t('highlighted_for_you')}
          </Heading>
          <ServicesView
            lazyLoading={lazyLoading}
            services={prominentServices}
          />
        </Box>
      )}
      {prominentServices.length > 0 && (
        <Heading as="h4" variant="h4" color="servicesOverviewHeadingColor">
          {t('everything')}
        </Heading>
      )}
      <ServicesView lazyLoading={lazyLoading} services={services} />
    </Main>
  );
};
